import React from 'react';
import PropTypes from 'prop-types';
import styled, { css } from 'styled-components';

import logo from 'assets/logo.svg';

const Wrapper = styled.header`
  ${({ theme }) => css`
    display: flex;
    align-items: center;
    height: 60px;
    background: ${theme.colors.header.primary};
  `}
`;

const Inner = styled.a`
  display: flex;
  align-items: center;
  width: 100%;
  margin: 0 auto;
  padding: 0 24px;

  @media (min-width: 768px) {
    padding: 0 36px;
  }
`;

const AppLogo = styled.img.attrs(() => ({ src: logo, width: 36, height: 36 }))`
  width: 36px;
  height: 36px;
`;

const SiteUrl = styled.div`
  ${({ theme }) => css`
    margin: 0 8px;
    font-size: 32px;
    line-height: 32px;
    font-weight: 600;
    color: ${theme.colors.control.primary};
  `}
`;

class Header extends React.PureComponent {
  static propTypes = {
    className: PropTypes.string
  };

  static defaultProps = {
    className: ''
  };

  render() {
    const { ...rest } = this.props;

    return (
      <Wrapper {...rest}>
        <Inner href="https://sanatory.ru">
          <AppLogo />

          <SiteUrl>
            Sanatory.ru
          </SiteUrl>
        </Inner>
      </Wrapper>
    );
  }
}

export default styled(Header)``;
