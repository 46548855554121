import React from 'react';
import { withTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import styled from 'styled-components';

const Wrapper = styled.div`
  font-size: 22px;
  font-weight: 600;
  line-height: 50px;
  
  @media (min-width: 375px) {
    font-size: 28px;
  }

  @media (min-width: 768px) {
    font-size: 36px;
  }
`;

class Amount extends React.PureComponent {
  static propTypes = {
    className: PropTypes.string,
    t: PropTypes.func.isRequired,
    value: PropTypes.number.isRequired
  };

  static defaultProps = {
    className: '',
    value: 0
  };

  render() {
    const { value, t, ...rest } = this.props;

    return (
      <Wrapper {...rest}>
        {t('UI.Price', { price: value })}
      </Wrapper>
    );
  }
}

export default styled(withTranslation()(Amount))``;
