import React from 'react';
import { BrowserRouter } from 'react-router-dom';
import { ThemeProvider, createGlobalStyle } from 'styled-components';

import { ReactComponentChildrenPropType } from 'types/customPropTypes';

import theme from 'theme';
import globalCSS from 'theme/css/global.css';

const GlobalStyle = createGlobalStyle`
  ${globalCSS}
`;

class MainProvider extends React.Component {
  static propTypes = {
    children: ReactComponentChildrenPropType.isRequired
  };

  render() {
    const { children } = this.props;

    return (
      <BrowserRouter>
        <ThemeProvider theme={theme}>
          <GlobalStyle />
          {children}
        </ThemeProvider>
      </BrowserRouter>
    );
  }
}

export default MainProvider;
