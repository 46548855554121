import React from 'react';
import PropTypes from 'prop-types';
import { observer } from 'mobx-react';
import styled from 'styled-components';

import { Spinner } from 'components/ui';

import theme from 'theme';

const Body = styled.div``;

const Wrapper = styled.button.attrs(({ type }) => ({ type }))`
  ${(props) => theme.Button(props)}
`;

const Loader = styled.div`
  margin-right: 8px;
`;

@observer
class Button extends React.Component {
  static propTypes = {
    className: PropTypes.string,
    disabled: PropTypes.bool,
    outline: PropTypes.bool,
    rounded: PropTypes.bool,
    loading: PropTypes.bool,
    compact: PropTypes.bool,
    size: PropTypes.oneOf(['small', 'medium', 'big']),
    variant: PropTypes.oneOf(['primary', 'secondary', 'danger', 'success']),
    children: PropTypes.any,
    type: PropTypes.string,
    plain: PropTypes.bool,
    href: PropTypes.string
  };

  static defaultProps = {
    className: '',
    disabled: false,
    outline: false,
    rounded: false,
    loading: false,
    compact: false,
    size: 'medium',
    variant: 'primary',
    type: null,
    plain: false
  };

  render() {
    const { children, loading, href, size, ...rest } = this.props;

    return (
      <Wrapper
        as={href && 'a'}
        href={href}
        loading={loading ? 'true' : undefined}
        size={size}
        {...rest}
      >
        {loading && (
          <Loader>
            <Spinner size={size} />
          </Loader>
        )}

        <Body>
          {children}
        </Body>
      </Wrapper>
    );
  }
}

export default styled(Button)``;
