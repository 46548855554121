import React from 'react';
import PropTypes from 'prop-types';
import { computed } from 'mobx';
import { observer } from 'mobx-react';
import styled from 'styled-components';
import PhoneNumberInput from 'react-phone-number-input/input'

import theme from 'theme';

const Wrapper = styled(PhoneNumberInput)`
  ${(props) => theme.TextInput(props)}
`;

@observer
class PhoneInput extends React.Component {
  static propTypes = {
    className: PropTypes.string,
    disabled: PropTypes.bool,
    rounded: PropTypes.bool,
    size: PropTypes.oneOf(['small', 'medium', 'large']),
    variant: PropTypes.oneOf(['primary', 'secondary', 'danger', 'success']),
    field: PropTypes.object,
    type: PropTypes.oneOf([
      'email',
      'number',
      'password',
      'tel',
      'text',
      'url',
      'time',
      'date'
    ]),
    plain: PropTypes.bool,
    onChange: PropTypes.func,
    onBlur: PropTypes.func,
    onFocus: PropTypes.func,
    onKeyUp: PropTypes.func,
    onKeyDown: PropTypes.func
  };

  static defaultProps = {
    className: '',
    disabled: false,
    rounded: false,
    size: 'medium',
    variant: 'primary',
    type: 'text',
    plain: false
  };

  @computed get hasError() {
    const { field } = this.props;
    return field && !!field.error;
  }

  render() {
    const { field, loading, ...rest } = this.props;

    return (
      <Wrapper
        error={this.hasError ? 1 : 0}
        smartCaret={false}
        useNationalFormatForDefaultCountryValue={false}
        {...field && field.bind()}
        {...rest}
      />
    );
  }
}

export default styled(PhoneInput)``;
