import colors from './colors';
import fonts from './fonts';
import typography from './typography';
import Button from './Button.theme';
import TextInput from './TextInput.theme';

export default {
  colors,
  fonts,
  typography,
  Button,
  TextInput
};
