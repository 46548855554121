import { css } from 'styled-components';
import Color from 'color';

import colors from 'theme/colors';

const transition = () => css`
  transition: all .2s ease-in-out;
`;

const size = ({ size }) => {
  if (size === 'medium') {
    return css`
      height: 48px;
      padding: 0 22px;
      font-size: 18px;
      font-weight: 600;
      border-radius: 4px;
    `;
  }

  if (size === 'small') {
    return css`
      height: 30px;
      padding: 0 12px;
      font-size: 14px;
      font-weight: 600;
      border-radius: 4px;
    `;
  }

  return css``;
};

const variant = ({ variant }) => {
  if (variant === 'primary') {
    return css`
      color: ${colors.light.primary};
      background-color: ${colors.control.secondary};
      border: 2px solid transparent;

      &:hover {
        background-color: #3AA6D2;
      }

      &:active {
        background-color: #2E96C1;
      }
    `;
  }

  if (variant === 'secondary') {
    return css`
      color: ${colors.control.primary};
      background-color: ${colors.light.primary};
      border: 2px solid transparent;

      &:hover {
        background-color: ${Color(colors.light.primary).darken(0.0625).hex()};
      }

      &:active {
        background-color: ${Color(colors.light.primary).darken(0.125).hex()};
      }
    `;
  }

  if (variant === 'success') {
    return css`
      color: ${colors.light.primary};
      background-color: ${colors.success.primary};
      border: 2px solid transparent;

      &:hover {
        background-color: #359965;
      }

      &:active {
        background-color: #257F50;
      }
    `;
  }

  return css``;
};

const outline = ({ variant }) => {
  if (variant === 'primary') {
    return css`
      color: #5AB2D6;
      border: 2px solid currentColor;

      &:hover {
        color: #FFFFFF;
        background-color: #3AA6D2;
        border-color: #3AA6D2;
      }

      &:active {
        color: #FFFFFF;
        background-color: #2E96C1;
        border-color: #2E96C1;
      }
    `;
  }

  if (variant === 'success') {
    return css`
      color: #62CB94;
      border: 2px solid currentColor;

      &:hover {
        color: #359965;
      }

      &:active {
        color: #257F50;
      }
    `;
  }

  return css``;
};

const compact = ({ variant }) => {
  if (variant === 'primary') {
    return css`
      color: #5AB2D6;

      &:hover {
        color: #3AA6D2;
      }

      &:active {
        color: #2E96C1;
      }
    `;
  }

  if (variant === 'secondary') {
    return css`
      color: #9CB4CA;

      &:hover {
        color: #6A8197;
      }

      &:active {
        color: #556B80;
      }
    `;
  }

  if (variant === 'danger') {
    return css`
      color: #E26161;

      &:hover {
        color: #BE4040;
      }

      &:active {
        color: #9D2F2F;
      }
    `;
  }

  if (variant === 'success') {
    return css`
      color: #62CB94;

      &:hover {
        color: #359965;
      }

      &:active {
        color: #257F50;
      }
    `;
  }

  return css``;
};

const disabled = () => css`
  pointer-events: none;
  opacity: 0.5;
`;

const rounded = ({ size }) => {
  if (size === 'medium') {
    return css`
      padding: 0 26px;
      border-radius: 9999px;
    `;
  }

  if (size === 'small') {
    return css`
      padding: 0 16px;
      border-radius: 9999px;
    `;
  }

  return css``;
};

const loading = () => css`
  pointer-events: none;
`;

const defaults = () => css`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  position: relative;
  cursor: pointer;
  background: transparent;
  border: 2px solid transparent;
  text-decoration: none;
  user-select: none;
`;

const theme = (props) => ([
  defaults(props),
  transition(props),
  size(props),
  (props.outline ? outline(props) : css``),
  (props.compact ? compact(props) : css``),
  (props.disabled ? disabled(props) : css``),
  (props.rounded ? rounded(props) : css``),
  (props.loading ? loading(props) : css``),
  (!props.compact && !props.outline ? variant(props) : css``)
]);

export default theme;
