import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

const Wrapper = styled.label`
  padding: 4px 16px;
  font-size: 18px;
  font-weight: 600;
  line-height: 22px;

  @media (min-width: 768px) {
    padding: 0;
    font-size: 14px;
  }
`;

class Label extends React.PureComponent {
  static propTypes = {
    className: PropTypes.string,
    field: PropTypes.object.isRequired
  };

  static defaultProps = {
    className: ''
  };

  render() {
    const { field, ...rest } = this.props;

    return (
      <Wrapper
        htmlFor={field.id}
        {...rest}
      >
        {field.label}
      </Wrapper>
    );
  }
}

export default styled(Label)``;
