import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import { Form } from 'components/ui';
import { Field, EmailInput } from 'components/forms';

const Wrapper = styled(Form.Row)`
  padding: 12px 0;

  ${Field.Label} {
    @media (min-width: 768px) {
      width: 22%;
    }
  }
`;

class EmailField extends React.PureComponent {
  static propTypes = {
    className: PropTypes.string,
    field: PropTypes.object.isRequired,
    mask: PropTypes.oneOfType([
      PropTypes.func,
      PropTypes.array
    ])
  };

  render() {
    const { field, ...rest } = this.props;

    return (
      <Wrapper>
        <Field.Label field={field} />

        <Field
          rounded
          component={EmailInput}
          field={field}
          {...rest}
        />
      </Wrapper>
    );
  }
}

export default styled(EmailField)``;
