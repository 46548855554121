import React from 'react';
import PropTypes from 'prop-types';
import styled, { css } from 'styled-components';

import { Header, Content } from '..';

import { ReactComponentChildrenPropType } from 'types/customPropTypes';

const Wrapper = styled.div`
  ${({ theme }) => css`
    display: flex;
    flex-direction: column;
    min-height: 100%;
    background: ${theme.colors.light.primary};
    font: 14px ${theme.fonts.MyriadPro};

    ${Header} {
      width: 100%;
    }

    ${Content} {
      flex-grow: 1;
      width: 100%;
    }
  `}
`;

class Body extends React.Component {
  static propTypes = {
    className: PropTypes.string,
    children: ReactComponentChildrenPropType.isRequired
  };

  static defaultProps = {
    className: ''
  };

  render() {
    const { children, ...rest } = this.props;

    return (
      <Wrapper {...rest}>
        {children}
      </Wrapper>
    );
  }
}

export default styled(Body)``;
