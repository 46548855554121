import { types } from 'mobx-state-tree';

const Traveller = types.model('Traveller', {
  id: types.identifier,
  first_name: types.string,
  last_name: types.string,
  middle_name: types.maybeNull(types.string)
});

export default Traveller;
