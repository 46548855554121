import React from 'react';
import { observer } from 'mobx-react';
// import DevTools from 'mobx-react-devtools';
import { Switch, Route, Redirect } from 'react-router-dom';

import { MainProvider } from 'components/common';
import { Payments, NotFound } from 'pages';
import { Body, Content, Header } from '.';

@observer
class App extends React.Component {
  render() {
    return (
      <MainProvider>
        <Body>
          <Header />

          <Content>
            <Switch>
              <Redirect
                exact
                from="/"
                to="/payments/new"
              />

              <Route
                path="/payments"
                component={Payments}
              />

              <Route
                path="*"
                component={NotFound}
              />
            </Switch>
          </Content>
        </Body>

        {/* {process.env.NODE_ENV === 'development' && (
          <DevTools position={{ bottom: 5, right: 20 }} />
        )} */}
      </MainProvider>
    );
  }
}

export default App;
