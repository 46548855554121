import React from 'react';
import { withTranslation } from 'react-i18next';
import { computed } from 'mobx';
import { inject, observer } from 'mobx-react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import { OrderDetails } from 'pages/Payments/components';

const Wrapper = styled(OrderDetails)``;

@inject('paymentStore')
@observer
class PaymentDetails extends React.Component {
  static propTypes = {
    className: PropTypes.string,
    paymentStore: PropTypes.object.isRequired,
    t: PropTypes.func
  };

  static defaultProps = {
    className: ''
  };

  @computed get isFetched() {
    const { paymentStore } = this.props;
    return paymentStore.isFetched;
  }

  @computed get payment() {
    const { paymentStore } = this.props;
    return paymentStore.data?.toJSON() || {};
  }

  @computed get acquiringProvider() {
    const { t } = this.props;
    const { acquiring_provider } = this.payment;

    const label = t('Booking.Form.AcquiringProvider.Label');
    let value = '';

    if (acquiring_provider === 'sberbank') {
      value = t('Booking.Form.AcquiringProvider.Sberbank');
    }

    if (acquiring_provider === 'mir_cashback') {
      value = t('Booking.Form.AcquiringProvider.MirCashback');
    }

    if (acquiring_provider === 'gazprombank') {
      value = t('Booking.Form.AcquiringProvider.Gazprombank');
    }

    return { label, value };
  }

  render() {
    const { paymentsForm, t, ...rest } = this.props;

    const {
      description,
      number,
      phone,
      email,
      acquiring_provider,
      traveller: {
        first_name,
        middle_name,
        last_name
      } = {}
    } = this.payment;

    return (
      <Wrapper {...rest}>
        <OrderDetails.Item
          label={t('Form.OrderCode.Label')}
          isFetching={!this.isFetched}
          value={number}
        />

        <OrderDetails.Item
          label={t('Form.Description.Label')}
          isFetching={!this.isFetched}
          value={description}
          wrapped={true}
        />

        <OrderDetails.Item
          label={t('Booking.Form.Traveller.Label')}
          isFetching={!this.isFetched}
          value={
            [
              last_name,
              first_name,
              middle_name
            ]
            .filter(item => item)
            .join(' ')
          }
        />

        <OrderDetails.Item
          label={t('Form.Phone.Label')}
          isFetching={!this.isFetched}
          value={phone}
        />

        <OrderDetails.Item
          label={t('Form.Email.Label')}
          isFetching={!this.isFetched}
          value={email}
        />

        <OrderDetails.Item
          isFetching={!this.isFetched}
          {...this.acquiringProvider}
        />
      </Wrapper>
    );
  }
}

export default styled(withTranslation()(PaymentDetails))``;
