import React from 'react';
import { Switch, Route } from 'react-router-dom';

import { Add, Show } from './containers';

class Payments extends React.PureComponent {
  render() {
    return (
      <Switch>
        <Route
          exact
          path='/payments/new'
          component={Add}
        />

        <Route
          exact
          path='/payments/:id'
          component={Show}
        />
      </Switch> 
    );
  }
}

export default Payments;
