import React from 'react';
import { withTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import { Amount } from 'pages/Payments/components';
import { Button } from 'components/forms';

const Wrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  padding: 48px 0 24px;

  ${Amount} {
    margin-right: 20px;

    @media (min-width: 768px) {
      margin-right: 36px;
    }
  }
`;

class PaymentSubmit extends React.PureComponent {
  static propTypes = {
    className: PropTypes.string,
    amount: PropTypes.number.isRequired,
    isPending: PropTypes.bool,
    t: PropTypes.func
  };

  static defaultProps = {
    className: '',
    isPending: false,
    amount: 0
  };

  render() {
    const { isPending, amount, t, ...rest } = this.props;

    return (
      <Wrapper {...rest}>
        <Amount value={amount} />

        <Button
          type="submit"
          rounded
          loading={isPending}
          disabled={isPending}
          variant="success"
        >
          {isPending ? t('Booking.Form.PendingSubmit') : t('Booking.Form.Submit')}
        </Button>
      </Wrapper>
    );
  }
}

export default styled(withTranslation()(PaymentSubmit))``;
