import React from 'react';
import PropTypes from 'prop-types';
import styled, { css } from 'styled-components';

import { typography } from 'theme/mixins';

const Wrapper = styled.dl``;

const Label = styled.dt`
  padding: 4px 0;
  ${typography('h3')}
`;

const Value = styled.dd`
  padding: 4px 0;
  font-size: 18px;
  line-height: 22px;
  white-space: ${props => (props.wrapped ? 'pre-wrap' : 'normal')};
`;

const Placeholder = styled.div.attrs(() => ({ width: `${Math.random() * (99 - 30) + 30}%` }))`
  ${({ theme, width }) => css`
    width: ${width};
    height: 22px;
    background: ${theme.colors.control.secondary};
  `}
`;

class Item extends React.PureComponent {
  static propTypes = {
    className: PropTypes.string,
    isFetching: PropTypes.bool,
    label: PropTypes.string,
    value: PropTypes.string,
    wrapped: PropTypes.bool
  };

  static defaultProps = {
    className: '',
    isFetching: false,
    wrapped: false
  };

  render() {
    const { isFetching, label, value, wrapped, ...rest } = this.props;

    return (
      <Wrapper {...rest}>
        <Label>
          {isFetching
            ? <Placeholder />
            : label}
        </Label>

        <Value wrapped={wrapped}>
          {isFetching
            ? <Placeholder />
            : value}
        </Value>
      </Wrapper>
    );
  }
}

export default styled(Item)``;
