import React from 'react';
import PropTypes from 'prop-types';
import { observer } from 'mobx-react';
import styled from 'styled-components';

import { Label as FieldLabel } from '.';

const Wrapper = styled.div`
  width: 100%;
`;

const Label = styled.label`
  user-select: none;
`;

const RawInput = styled.input``;

@observer
class Field extends React.Component {
  static propTypes = {
    className: PropTypes.string,
    component: PropTypes.oneOfType([PropTypes.func, PropTypes.object]),
    field: PropTypes.object,
    name: PropTypes.string,
    hasLabel: PropTypes.bool
  };

  static defaultProps = {
    className: '',
    component: RawInput
  };

  static Label = FieldLabel;

  render() {
    const { className, hasLabel, field, component, ...rest } = this.props;

    if (field) {
      const Component = component;

      return (
        <Wrapper className={className}>
          {(hasLabel && field.label) && (
            <Label htmlFor={field.id}>
              {field.label}
            </Label>
          )}

          <Component
            {...rest}
            field={field}
          />
        </Wrapper>
      );
    }

    return null;
  }
}

export default styled(Field)``;
