export default {
  h1: {
    fontSize: [42, 'px'],
    fontWeight: 600,
    lineHeight: [50, 'px']
  },
  h2: {
    fontSize: [24, 'px'],
    fontWeight: 600,
    lineHeight: [29, 'px']
  },
  h3: {
    fontSize: [18, 'px'],
    fontWeight: 600,
    lineHeight: [22, 'px']
  }
};
