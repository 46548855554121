import React from 'react';
import PropTypes from 'prop-types';
import { computed } from 'mobx';
import { observer } from 'mobx-react';
import styled from 'styled-components';

import { Form } from 'components/ui';
import { Field } from 'components/forms';
import { NameField } from '.';

import theme from 'theme';

const Wrapper = styled(Form.Row)`
  padding: 12px 0;

  ${Field.Label} {
    @media (min-width: 768px) {
      width: 22%;
    }
  }
`;

const Fields = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;

  ${(props) => theme.TextInput({
    ...props,
    variant: 'primary',
    rounded: true
  })}

  @media (max-width: 767px) {
    height: auto;
  }

  @media (min-width: 768px) {
    flex-direction: row;

    ${NameField} {
      width: 100%;

      :last-child {
        width: calc(100% - 24px);
      }

      :not(:last-child)::after {
        content: "";
        width: 1px;
        height: 28px;
        margin: 0 12px;
        background: ${theme.colors.control.secondary};
      }
    }
  }
`;

@observer
class Traveller extends React.Component {
  static propTypes = {
    className: PropTypes.string,
    hasError: PropTypes.bool,
    disabled: PropTypes.bool,
    traveller: PropTypes.object.isRequired,
    size: PropTypes.string
  };

  static defaultProps = {
    className: '',
    disabled: false,
    size: 'medium'
  };

  @computed get hasError() {
    const { traveller } = this.props;
    return traveller.isFilled ? 0 : 1;
  }

  render() {
    const { disabled, traveller, size, ...rest } = this.props;

    return (
      <Wrapper {...rest}>
        <Field.Label field={traveller} />

        <Fields
          error={this.hasError}
          size={size}
        >
          <NameField
            field={traveller.$('last_name')}
            disabled={disabled}
          />

          <NameField
            field={traveller.$('first_name')}
            disabled={disabled}
          />
          
          <NameField
            field={traveller.$('middle_name')}
            disabled={disabled}
          />
        </Fields>
      </Wrapper>
    );
  }
}

export default styled(Traveller)``;
