import React from 'react';
import PropTypes from 'prop-types';
import styled, { css } from 'styled-components';

import { ErrorIcon } from 'components/icons';

const Wrapper = styled.div`
  ${({ theme }) => css`
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 8px 0;
    color: ${theme.colors.error.primary};
    font-size: 16px;
    line-height: 20px;
  `}
`;

const Icon = styled(ErrorIcon)`
  width: 20px;
  height: 20px;
  margin-right: 8px;
`;

class FormError extends React.PureComponent {
  static propTypes = {
    error: PropTypes.string
  };
  
  static defaultProps = {
    error: null
  };
  
  render() {
    const { error } = this.props;

    return (
      <Wrapper>
        <Icon />

        {error}
      </Wrapper>
    );
  }
}

export default styled(FormError)``;
