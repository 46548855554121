import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { computed } from 'mobx';
import { observer } from 'mobx-react';
import Select from 'react-select';
import styled from 'styled-components';

import { MdKeyboardArrowDown, MdKeyboardArrowUp } from 'react-icons/md';
import colors from 'theme/colors';

const customStyles = {
  option: (provided, state) => ({
    ...provided,
    fontSize: 18,
    padding: 2,
    lineHeight: 1.5,
    paddingLeft: 10,
  }),
  control: () => ({
    width: '100%',
    height: 48,
    border: `1px solid ${colors.control.secondary}`,
    borderRadius: 24,
    display: 'flex',
    fontSize: 18,
    paddingLeft: 10
  }),
  singleValue: (provided, state) => {
    const opacity = state.isDisabled ? 0.5 : 1;
    const transition = 'opacity 300ms';

    return { ...provided, opacity, transition };
  }
}

const Wrapper = styled.div`
`;

@observer
class SelectInput extends Component {
  static propTypes = {
    field: PropTypes.object.isRequired,
    options: PropTypes.array,
    disabled: PropTypes.bool,
    clearable: PropTypes.bool,
    searchable: PropTypes.bool,
    simpleValue: PropTypes.bool,
    className: PropTypes.string,
    selectFirst: PropTypes.bool
  }

  static defaultProps = {
    isLoading: false,
    clearable: false,
    searchable: false,
    simpleValue: true,
    className: '',
    disabled: false,
    selectFirst: false
  }

  constructor(props) {
    super(props);

    const { selectFirst } = props;
    const options = this.selectOptions

    if (selectFirst && options.length > 0) {
      this.onChange(options[0])
    }
  }

  arrowRenderer = ({ isOpen }) => {
    return isOpen
      ? <MdKeyboardArrowUp />
      : <MdKeyboardArrowDown />;
  }

  onChange = (value) => {
    const { field } = this.props;

    field.set(value.value);
  }

  @computed get selectOptions() {
    const { field, options } = this.props;

    return options || field.extra || [];
  }

  render() {
    const {
      field,
      disabled,
      clearable,
      searchable,
      simpleValue,
      className,
    } = this.props;

    return (
      <Wrapper>
        <Select
          {...field.bind({ onChange: this.onChange })}
          className={className}
          isLoading={disabled}
          options={this.selectOptions}
          clearable={clearable}
          searchable={searchable}
          isOpen={true}
          simpleValue={simpleValue}
          disabled={!this.selectOptions.length}
          arrowRenderer={this.arrowRenderer}
          value={this.selectOptions.filter(({value}) => value === field.value)}
          styles={customStyles}
          theme={theme => ({
            ...theme,
            colors: {
              ...theme.colors,
              primary25: colors.success.fourth,
              primary50: colors.success.secondary,
              primary: colors.success.third
            },
          })}
        />
      </Wrapper>
    );
  }
}

export default SelectInput;
