import { css } from 'styled-components';

import colors from 'theme/colors';

const transition = () => css`
  transition: all .25s ease-in-out;
`;

const size = ({ size, rows = 1 }) => {
  if (size === 'medium') {
    return css`
      padding-left: 16px;
      padding-right: 16px;
      border-radius: 4px;
      font-size: 18px;

      ${rows === 1 && css`
        height: 48px;
      `}
    `;
  }

  return css``;
};

const variant = ({ variant, size }) => {
  if (variant === 'primary') {
    return css`
      background-color: ${colors.light.primary};
      border-color: ${colors.control.secondary};

      &:focus {
        border-color: ${colors.control.primary};
      }

      ${size === 'medium' && css`
        padding-top: 12px;
        padding-bottom: 12px;
      `}
    `;
  }

  return css``;
};

const disabled = () => css`
  color: ${colors.control.secondary};
`;

const error = ({ error }) => {
  if (error === 1) {
    return css`
      border-color: ${colors.error.primary};
    `;
  }
};

const rounded = ({ size }) => {
  if (size === 'medium') {
    return css`
      padding-left: 24px;
      padding-right: 24px;
      border-radius: 24px;
    `;
  }

  return css``;
};

const plain = () => css`
  padding-left: 0;
  padding-right: 0;
`;

const defaults = () => css`
  width: 100%;
  background: transparent;
  border: 1px solid transparent;
  color: ${colors.text.primary};

  ::placeholder {
    color: ${colors.control.secondary};
  }
  overflow: hidden;
`;

const theme = (props = {}) => ([
  defaults(props),
  transition(props),
  size(props),
  (props.rounded && !props.plain ? rounded(props) : css``),
  (!props.plain ? variant(props) : css``),
  (props.plain ? plain() : css``),
  (props.disabled ? disabled(props) : css``),
  (!props.disabled && !props.plain ? error(props) : css``)
]);

export default theme;
