import React from 'react';
import PropTypes from 'prop-types';
import styled, { css } from 'styled-components';

import { ErrorIcon } from 'components/icons';

import { ReactComponentChildrenPropType } from 'types/customPropTypes';

import { typography } from 'theme/mixins';

const Wrapper = styled.div`
  ${({ theme }) => css`
    display: flex;
    align-items: center;
    padding: 8px 0;
    ${typography('h2')}
    color: ${theme.colors.error.primary};
  `}
`;

const Icon = styled(ErrorIcon)`
  width: 32px;
  height: 32px;
  margin-right: 8px;
`;

class Title extends React.PureComponent {
  static propTypes = {
    className: PropTypes.string,
    children: ReactComponentChildrenPropType.isRequired
  };

  render() {
    const { children, ...rest } = this.props;

    return (
      <Wrapper {...rest}>
        <Icon />

        {children}
      </Wrapper>
    );
  }
}

export default styled(Title)``;
