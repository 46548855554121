import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import { ReactComponentChildrenPropType } from 'types/customPropTypes';

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  padding: 4px 0;

  @media (min-width: 768px) {
    flex-direction: row;
    align-items: center;
  }
`;

class FormRow extends React.PureComponent {
  static propTypes = {
    className: PropTypes.string,
    children: ReactComponentChildrenPropType.isRequired
  };

  render() {
    const { children, ...rest } = this.props;

    return (
      <Wrapper {...rest}>
        {children}
      </Wrapper>
    );
  }
}

export default styled(FormRow)``;
