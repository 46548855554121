import { kebabCase } from 'lodash';
import { css } from 'styled-components';

import theme from 'theme';

export function typography(name) {
  const styles = theme.typography[name];

  if (!styles) return null;

  return css`
    ${Object.entries(styles).map(([propName, value]) => [
      kebabCase(propName),
      Array.isArray(value) ? value.join('') : value
    ].join(':')).join(';')};
  `;
}
