import createNumberMask from 'text-mask-addons/dist/createNumberMask';

export const inputMask = {
  number: () => ({
    mask: createNumberMask({
      prefix: '',
      thousandsSeparatorSymbol: ''
    })
  }),

  orderCode: () => ({
    guide: false,
    mask: [
      /[a-zA-Z]/, /[a-zA-Z]/, /[a-zA-Z]/, '-', /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/, /\d/, '-', /\d/
    ]
  }),

  // TODO: Allow multi regional format
  phone: () => ({
    guide: false,
    mask: ['+7 ', /[1-9]/, /\d/, /\d/, ' ', /\d/, /\d/, /\d/, '-', /\d/, /\d/, '-', /\d/, /\d/]
  }),

  email: () => ({
    guide: false,
    mask: [
      /[a-zA-Z0-9._%+-]/, /[a-zA-Z0-9._%+-]/, /[a-zA-Z0-9._%+-]/, /[a-zA-Z0-9._%+-]/, 
      /[a-zA-Z0-9._%+-]/, /[a-zA-Z0-9._%+-]/, '@', /[a-zA-Z0-9.-]/, /[a-zA-Z0-9.-]/,
      '.', /[a-zA-Z]/, /[a-zA-Z]/
    ]
  })
};
