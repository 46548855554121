import React from 'react';
import { withTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import styled, { css } from 'styled-components';

import { TextCopy } from 'components/ui';
import { SuccessSmallIcon } from 'components/icons';

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 24px 0;

  ${TextCopy} {
    width: 100%;
  }
`;

const Title = styled.div`
  ${({ theme }) => css`
    display: flex;
    padding: 16px 0;
    font-size: 18px;
    color: ${theme.colors.success.primary};
  `}
`;

const Icon = styled(SuccessSmallIcon)`
  width: 20px;
  height: 20px;
  margin-right: 8px;
  fill: currentColor;
`;

class PaymentLink extends React.PureComponent {
  static propTypes = {
    className: PropTypes.string,
    payment_url: PropTypes.string.isRequired,
    t: PropTypes.func
  };

  static defaultProps = {
    className: ''
  };

  render() {
    const { payment_url, t, ...rest } = this.props;

    return (
      <Wrapper {...rest}>
        <Title>
          <Icon />

          {t('Booking.Form.PaymentLink.Title')}
        </Title>

        <TextCopy url={payment_url} />
      </Wrapper>
    );
  }
}

export default styled(withTranslation()(PaymentLink))``;
