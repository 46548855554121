export function copyTextFromNode(node) {
  const selection = window.getSelection();
  const range = document.createRange();
  range.selectNodeContents(node);
  selection.removeAllRanges();
  selection.addRange(range);
  document.execCommand('Copy');

  const text = selection.toString();
  selection.removeAllRanges();

  return text;
}
