import React from 'react';
import { withTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import styled, { css } from 'styled-components';

import { ErrorIcon, SuccessSmallIcon } from 'components/icons';

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 16px 0;
`;

const Title = styled.div`
  ${({ type, theme }) => css`
    display: flex;
    padding: 16px 0;
    font-size: 18px;

    ${type === 'error' && css`
      color: ${theme.colors.error.primary};
    `}

    ${type === 'success' && css`
      color: ${theme.colors.success.primary};
    `}
  `}
`;

const ErrorStatusIcon = styled(ErrorIcon)`
  width: 20px;
  height: 20px;
  margin-right: 8px;
  fill: currentColor;
`;

const SuccessStatusIcon = styled(SuccessSmallIcon)`
  width: 20px;
  height: 20px;
  margin-right: 8px;
  fill: currentColor;
`;

class Status extends React.PureComponent {
  static propTypes = {
    className: PropTypes.string,
    status: PropTypes.number.isRequired,
    t: PropTypes.func
  };

  static defaultProps = {
    className: ''
  };

  get status() {
    const { status, t } = this.props;

    switch (status) {
      case -1:
        return {
          type: 'error',
          text: t('Booking.Statuses.RegistrationError')
        };
      
      case 1:
        return {
          type: 'info',
          text: t('Booking.Statuses.AuthorizationHold')
        };
      
      case 2:
        return {
          type: 'success',
          text: t('Booking.Statuses.PaymentSuccess')
        };

      case 3:
        return {
          type: 'error',
          text: t('Booking.Statuses.AuthorizationCancelled')
        };

      case 4:
        return {
          type: 'error',
          text: t('Booking.Statuses.TransactionRefunded')
        };

      case 5:
        return {
          type: 'info',
          text: t('Booking.Statuses.ACSAuthorizationInit')
        };

      case 6:
        return {
          type: 'error',
          text: t('Booking.Statuses.AuthorizationDeclined')
        };

      case 10:
        return {
          type: 'info',
          text: t('Booking.Statuses.Cash')
        };

      default:
        return null;
    }
  }

  render() {
    const { t, ...rest } = this.props;

    if (!this.status) {
      return null;
    }

    return (
      <Wrapper {...rest}>
        <Title type={this.status.type}>
          {this.status.type === 'error' && <ErrorStatusIcon />}
          {this.status.type === 'success' && <SuccessStatusIcon />}

          {this.status.text}
        </Title>
      </Wrapper>
    );
  }
}

export default styled(withTranslation()(Status))``;
