import React from 'react';
import { PropTypes } from 'prop-types';
import { withTranslation } from 'react-i18next';
import { Provider, observer } from 'mobx-react';
import ReactRouterPropTypes from 'react-router-prop-types';

import { PaymentTemplate } from 'pages/Payments/components';
import { Details } from '.';

import { One as PaymentStore } from 'stores/PaymentsStore';

@withTranslation()
@observer
class Show extends React.Component {
  static propTypes = {
    match: ReactRouterPropTypes.match.isRequired,
    t: PropTypes.func
  };

  constructor(props) {
    super(props);

    this.paymentStore = PaymentStore.create();
  }

  get title() {
    const { t } = this.props;
    const payment = this.paymentStore.data?.toJSON();

    if (payment) {
      if (payment.status === 2) {
        return t('Booking.Success.Title');
      }

      return t('Booking.Details.Title');
    }

    return t('Booking.Details.Pending');
  }

  componentDidMount() {
    const { match } = this.props;
    const { id } = match.params;

    this.paymentStore.fetch(id);
  }

  render() {
    return (
      <Provider paymentStore={this.paymentStore}>
        <PaymentTemplate>
          <PaymentTemplate.Header>
            {this.title}
          </PaymentTemplate.Header>

          <Details />
        </PaymentTemplate>
      </Provider>
    );
  }
}

export default Show;
