import React from 'react';
import PropTypes from 'prop-types';
import styled, { css } from 'styled-components';

import { ReactComponentChildrenPropType } from 'types/customPropTypes';

import { typography } from 'theme/mixins';

const Wrapper = styled.div`
  max-width: 680px;
  margin: 0 auto;
  padding: 20px;

  @media (min-width: 768px) {
    padding: 40px;
  }
`;

const Header = styled.div`
  ${({ theme }) => css`
    padding: 8px 0 32px;
    ${typography('h1')}
    border-bottom: 1px solid ${theme.colors.control.secondary};
  `}
`;

const Body = styled.div``;

const Footer = styled.div`
  ${({ theme }) => css`
    border-top: 1px solid ${theme.colors.control.secondary}; 
  `}
`;

class PaymentTemplate extends React.PureComponent {
  static propTypes = {
    className: PropTypes.string,
    children: ReactComponentChildrenPropType.isRequired
  };

  static defaultProps = {
    className: ''
  };

  static Header = Header;

  static Body = Body;

  static Footer = Footer;

  render() {
    const { children, ...rest } = this.props;

    return (
      <Wrapper {...rest}>
        {children}
      </Wrapper>
    );
  }
}

export default PaymentTemplate;
