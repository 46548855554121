import { Field } from 'mobx-react-form';

import { BaseForm } from 'forms';
import { TravellerField } from 'forms/fields';

import { fields } from '.';

class PaymentsForm extends BaseForm {
  setup() {
    return fields;
  }

  makeField(props) {
    const re = /^traveller$/;

    if (re.exec(props.path)) {
      return new TravellerField(props);
    }

    return new Field(props);
  }
}

export default PaymentsForm;
