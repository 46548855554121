import React from 'react';
import styled from 'styled-components';

const Wrapper = styled.div``;

class NotFound extends React.PureComponent {
  render() {
    return (
      <Wrapper>
        <h1>Page not found</h1>
      </Wrapper>
    );
  }
}

export default NotFound;
