import React from 'react';
import PropTypes from 'prop-types';
import { observer } from 'mobx-react';
import styled from 'styled-components';

import { Field, TextInput } from 'components/forms';

const Wrapper = styled.div`
  display: flex;
  align-items: center;
`;

@observer
class NameField extends React.Component {
  static propTypes = {
    className: PropTypes.string,
    disabled: PropTypes.bool,
    field: PropTypes.object.isRequired
  };

  static defaultProps = {
    className: '',
    disabled: false
  };

  render() {
    const { field, disabled, ...rest } = this.props;

    return (
      <Wrapper {...rest}>
        <Field
          plain
          disabled={disabled}
          field={field}
          component={TextInput}
        />
      </Wrapper>
    );
  }
}

export default styled(NameField)``;
