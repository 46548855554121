export default {
  control: {
    primary: '#000000',
    secondary: '#dbdbdb'
  },
  error: {
    primary: '#f39393'
  },
  header: {
    primary: '#f1e6df'
  },
  light: {
    primary: '#ffffff'
  },
  success: {
    primary: '#00bb58',
    secondary: '#5edb94',
    third: '#7bd38f',
    fourth: '#dff5e2'
  },
  text: {
    primary: '#4c4c4c'
  },
};
