import React from 'react';
import { withTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import styled, { css } from 'styled-components';
import { observable } from 'mobx';
import { observer } from 'mobx-react';

import { Button } from 'components/forms';

import { copyTextFromNode } from 'utils/text';

const COPIED_STATUS_LIFE_DURATION = 2000;

const Wrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: relative;
  height: 48px;
  padding: 8px;
  background: #f5f5f7;
  border-radius: 999px;

  ${Button} {
    box-shadow: 0 2px 24px 0 rgba(0, 0, 0, 0.2);
  }
`;

const Text = styled.div`
  width: 100%;
  padding: 0 24px 0 12px;
  font-size: 18px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`;

const CopiedStatus = styled.div`
  ${({ isCopied }) => css`
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    top: -16px;
    left: 0;
    width: 100%;
    height: 100%;
    background: #f5f5f7;
    border-radius: 999px;
    opacity: 0;
    pointer-events: none;
    transition:
      top 0.25s,
      opacity 0.25s;

    ${isCopied && css`
      top: 0;
      opacity: 1;
    `}
  `}
`;

@observer
class TextCopy extends React.PureComponent {
  static propTypes = {
    className: PropTypes.string,
    url: PropTypes.string.isRequired,
    t: PropTypes.func
  };

  static defaultProps = {
    className: ''
  };

  @observable isCopied = false;

  textNode = React.createRef();

  handleTextCopy = () => {
    const text = copyTextFromNode(this.textNode.current);
    
    if (!!text) {
      this.isCopied = true;

      setTimeout(
        () => {
          this.isCopied = false;
        },
        COPIED_STATUS_LIFE_DURATION
      );
    }
  }

  render() {
    const { url, t, ...rest } = this.props;

    return (
      <Wrapper {...rest}>
        <Text ref={this.textNode}>
          {url}
        </Text>

        <Button
          type="button"
          rounded
          size="small"
          variant="secondary"
          onClick={this.handleTextCopy}
        >
          {t('UI.Copy')}
        </Button>

        <CopiedStatus isCopied={this.isCopied}>
          {t('UI.LinkCopied')}
        </CopiedStatus>
      </Wrapper>
    );
  }
}

export default styled(withTranslation()(TextCopy))``;
